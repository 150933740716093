<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="justify-start flex-grow w-full text-yellow-50 p-2 pb-1">
        <div class="text-2xl">Add Category</div>

        <div class="flex justify-center">
          <br />
          <img src="@/assets/svg/question.svg" class="w-60" alt="" />
        </div>

        <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-sm">
          <div class="flex">
            <div class="w-full" style="height: 100px">
              <div class="flex flex-col">
                <div>
                  <div class="mt-2">
                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                    >
                      <input
                        id="amount"
                        v-model="category"
                        placeholder="-"
                        class="flex-grow w-full outline-none text-lg bg-transparent"
                        min="0"
                        step="0.00000001"
                      />

                      <button
                        v-if="loading"
                        class="flex-none text-sm px-2 py-2 border rounded text-gray-400 border-gray-400 cursor-wait"
                        disabled
                      >
                        <i class="fad fa-spinner-third fa-spin m-r-xs"></i>
                        Loading
                      </button>

                      <button
                        @click="addCategory()"
                        class="w-32 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-600 to-green-600"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-sm">
          <div
            v-for="t in categoryList.category"
            v-bind:key="t._id"
            class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
          >
            <div class="flex-grow flex flex-col sm:flex-row">
              <span class="hidden md:block mr-4 text-gray-500 text-sm">{{
                t.createdAt
              }}</span>

              <div class="text-sm sm:text-base">
                {{ t.remark }},
                <span class="text-xs"> : {{ t.category_name }} </span>
              </div>
            </div>
            <div
              class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
            >
              <button
                @click="deleteCategory(t)"
                class="w-32 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-red-400 to-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <!-- {{ categorySelect }} -->
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <footer>
          <StickFooterAdmin />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooterAdmin from "@/components/StickFooterAdmin.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";
import {
  POOL_ADDRESS,
  POOL_ADDRESS_ODD,
  VERSION,
  EXPLORER,
} from "../../../config";

import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import user from "@/api/user";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

import transactions from "../../public/transactions_bet.vue";

import Web3 from "web3";

import info from "@/api/info";

import blocks from "@/api/blocks";

import category from "@/api/category";

export default {
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooterAdmin,
    FundSummary,
    loader,
    chameleon,
    loader,
    chameleon,
    transactions,
  },
  computed: mapGetters(["getCurrency", "getOnBlock", "getUser", "getBalance"]),

  data() {
    return {
      funds: [],
      ready: false,
      category: "-",
      categoryList: [],
      categorySelect: [],
    };
  },
  methods: {
    addCategory() {
      category.addCategory({ category_name: this.category }).then((res) => {
        this.getCategory();
      });
    },

    deleteCategory(i) {
      category.DeleteCategory({ category_id: i._id }).then((res) => {
        this.getCategory();
      });
    },

    async getCategory() {
      category.categoryList().then((res) => {
        this.categoryList = res.data;

        var a = res.data.category.map((c) => {
          c.select = false;
          return c;
        });

        this.categorySelect = a;
      });
    },
  },
  created() {
    this.getCategory();
  },
  updated() {},
};
</script>
<style scoped></style>
