import api from "@/api/index";

export default {
  categoryList(credentials) {
    return api().get("category", credentials);
  },

  DeleteCategory(credentials) {
    return api().post("category/delete", credentials);
  },

  addCategory(credentials) {
    return api().post("category/create", credentials);
  },
};
